var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SALES_ORDERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List SalesOrders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-bag-17"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SALES_ORDERS")))])])]}}],null,false,1926997456)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SALES_INVOICES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List SalesInvoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-single-copy-04"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SALES_INVOICES")))])])]}}],null,false,3870881627)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SALES_PAYMENTS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List SalesPayments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-money-coins"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SALES_PAYMENTS")))])])]}}],null,false,46250574)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SALES_DELIVERIES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List SalesDeliveries' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"fa fa-2x fa-truck"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SALES_DELIVERIES")))])])]}}],null,false,3496950435)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }